import React from 'react'
import HookForm from '../components/hookForm'


// import React from "react"
import { graphql } from "gatsby"

// import { Box } from "@chakra-ui/react"
import Layout from "../components/layout"


export const data = graphql`
  query contactForm {
    contentfulNavbar {
      showBurgerBarOnly
      logo {
        fixed {
          src
        }
        fluid(maxWidth: 2048) {
          ...GatsbyContentfulFluid
        }
      }
      navbarItems {
        linkTo {
          title
          slug
        }
      }
    }
    contentfulPage(slug: {eq: "aboutyou"}) {
      slug
      title
      metaTitle
      metaDescription
      metaLogo {
        fixed {
          ...GatsbyContentfulFixed
        }
      }
      bgImage {
        image {
          fluid(maxWidth: 2048) {
            ...GatsbyContentfulFluid
          }
        }
      }
      sections {
        title
        section_components {
          __typename
          ... on Node {
            ... on ContentfulComponentButton {
              buttonText
            }
            ... on ContentfulComponentImageBlock {
              image {
                image {
                  fixed {
                    src
                  }
                  fluid(maxWidth: 2048) {
                    ...GatsbyContentfulFluid
                  }
                }
              }
            }
            ... on ContentfulComponentTextBlock {
              text {
                text
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
      }
    }
  }
`

const Contact = props => {
  return (
    <Layout navbar={props.data.contentfulNavbar}
            id="pageTemplate" 
            slug={props.data.contentfulPage.slug}
            metaTitle={props.data.contentfulPage.metaTitle}
            metaDescription={props.data.contentfulPage.metaDescription}
            metaImage={props.data.contentfulPage.metaLogo.fixed}
            bgImage={props.data.contentfulPage.bgImage} 
            title={"Contact Us"} 
            height="100%">
      <HookForm />
    </Layout>
  )
}

export default Contact
